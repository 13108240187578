<template>
  <div class="container">
    <div class="content">
      <div class="title">
        <div class="title-top">
          <h1 class="title-h1">ENTERPRISE</h1>
        </div>
        <div class="title-middle">
          <img src="../assets/honor/icon.png"
               class="img-icon"
               alt="">
          <h1 class="title-h1">HONOR</h1>
        </div>
        <div class="title-bottom">
          企业荣誉
        </div>
      </div>
      <div class="honor">
        <div class="item"
             v-for="item in HonorLists"
             :key="item.src">
          <div class="card">
            <img class="img"
                 :src="item.src"
                 alt="">
          </div>
          <div class="text">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Honor',
  data () {
    return {
      HonorLists: [
        {
          src: require('../assets/honor/1.png'),
          title: '2014 “双百计划”领军型人才创业企业'
        }, {
          src: require('../assets/honor/2.png'),
          title: '2014高新技术企业证书'
        }, {
          src: require('../assets/honor/3.png'),
          title: '2017高新技术企业证书'
        }, {
          src: require('../assets/honor/4.png'),
          title: '2017年厦门市科技小巨人企业证书'
        }, {
          src: require('../assets/honor/5.png'),
          title: '2018年度纳税明星企业'
        }, {
          src: require('../assets/honor/6.png'),
          title: '2019年度纳税明星'
        }, {
          src: require('../assets/honor/7.png'),
          title: '2019最具成长性创业企业'
        }, {
          src: require('../assets/honor/8.png'),
          title: '2020-2021年度厦门市最具成长性中小微企业'
        }, {
          src: require('../assets/honor/9.png'),
          title: '2020-2022年度专精特新中小企业'
        }, {
          src: require('../assets/honor/10.png'),
          title: '2020高新技术企业证书'
        }, {
          src: require('../assets/honor/11.png'),
          title: '2020年度纳税明星企业'
        }, {
          src: require('../assets/honor/12.png'),
          title: '2020年厦门市小巨人领军企业证书'
        }, {
          src: require('../assets/honor/13.png'),
          title: '2021年度纳税明星企业'
        }, {
          src: require('../assets/honor/14.png'),
          title: '2022年最具成长性企业'
        }, {
          src: require('../assets/honor/15.png'),
          title: '2022厦门市未来产业骨干企业'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    color: #fff;
    height: 100%;
    padding: 22% 15% 0;
    background: url("../assets/honor/bgc.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .title {
      color: #fff;
      padding-bottom: 25%;
      box-sizing: border-box;
      .img-icon {
        height: 50px;
        padding-right: 6%;
      }
      .title-top,
      .title-middle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .title-h1 {
        font-size: 70px;
      }
      .title-bottom {
        display: flex;
        justify-content: flex-end;
        font-size: 47px;
        font-weight: bold;
      }
    }
    .honor {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 40%;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 3%;
        .text {
          padding-top: 3%;
          color: #000;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .container {
    .content {
      .title {
        .title-top {
          .title-h1 {
            font-size: 28px;
          }
        }
        .title-middle {
          .title-h1 {
            font-size: 28px;
          }
          .img-icon {
            height: 30px;
          }
        }
        .title-bottom {
          font-size: 25px;
        }
      }
    }
  }
}

// 大于
@media only screen and (max-width: 1600px) {
  .container {
    .content {
      padding: 22% 15% 0;
      .honor {
        .item {
          width: 40%;
          .card {
            .img {
              width: 100%;
            }
          }
          .text {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>